import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
} from "@mui/material";
import React from "react";
import GenericAddItem from "./GenericAddItem";
import GenericEditModal from "./GenericEditModal";
import GenericListItems from "./GenericListItems";

export default function GenericInputLayout({ header, newHeader, inputs, items, createAction, onCreate, onEdit, onDelete, getDisplayName, getListContent }) {
  const [open, setOpen] = React.useState(false);

  const handleModalClose = () => {
    setOpen(false);
  }

  return (
      <>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <GenericAddItem inputs={inputs.inputs} header={newHeader} createAction={createAction} onCreate={onCreate} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Card>
                <CardHeader title={header} />
                <CardContent>
                  {getListContent != null
                      ? getListContent(setOpen)
                      : <GenericListItems {...{ items, inputs, onDelete, setOpen, getDisplayName }} />}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <GenericEditModal {...{inputs, onEdit, open, handleModalClose}} />
      </>
  );
}