import React, {useState} from "react";
import {Box, Collapse, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import GenericListItems from "../../layouts/GenericInput/GenericListItems";

export default function ItemList({ item, inputs, onDelete, openModal }) {
  const [open, setOpen] = useState(false);

  const handleDelete = (e, categoryItem) => {
    onDelete(e, item.id, categoryItem);
  }

  return (
      <>
        <ListItemButton onClick={() => setOpen(!open)}>
          <ListItemText>{item.name} {item.items.length > 0 && `(${item.items.length})`}</ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open}>
          <>
            <Box ml={1}>
              {item.items.length > 0
                  ? (
                      <GenericListItems items={item.items} inputs={inputs} onDelete={handleDelete} setOpen={openModal} getDisplayName={item => item.name} />
                  ) : (
                      <ListItem>
                        <ListItemText>No Items</ListItemText>
                      </ListItem>
                  )}
            </Box>
          </>
        </Collapse>
      </>
  )
}