// component
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'home',
    path: '/home',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'categories',
    path: '/categories',
    icon: getIcon('clarity:list-solid'),
  },
  {
    title: 'items',
    path: '/items',
    icon: getIcon('clarity:list-solid'),
  },
];

export default navConfig;