import React from 'react';

export default function useInputs(passedInputs) {

  const [inputs, setInputs] = React.useState([...passedInputs]);
  const [editInputs, setEditInputs] = React.useState([...passedInputs, { name: 'id', type: 'hidden'}]);

  const updateEditInputs = (item) => {
    editInputs.map(input => {
      for (const prop in item) {
        const match = input.name
            .split('.')
            .some(it => it === prop);
        if (match) {
          if (input.type === 'autocomplete') {
            if (input.name.includes('.')) {
              const property = input.name.split('.')[0];
              input.value = item[property][input.optionValue];
            } else {
              input.value = item[input.name];
            }
          } else {
            input.value = item[prop];
          }
        }
      }
      return input;
    });

    setEditInputs([...editInputs]);
  }

  return {
    inputs,
    setInputs,
    editInputs,
    updateEditInputs
  }
}