import Page from "../components/Page";
import {Box, Container, Typography} from "@mui/material";
import Randomizer from "../components/Randomizer";
import React, {useContext} from "react";
import {GlobalContext} from "../providers/GlobalProvider";
import {GetCategoriesAndItems} from "../api/ItemApi";
import {ErrorHandler} from "../utils/ErrorHandler";
import {useSnackbar} from "notistack";
import Categories from "../components/Categories";

export default function HomePage () {
  const ctx = useContext(GlobalContext);
  const {enqueueSnackbar} = useSnackbar();
  const [categories, setCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);

  React.useEffect(() => {
    if (ctx.token) {
      setLoading(true);
      GetCategoriesAndItems().then(json => {
        setCategories(json);
      })
      .catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar))
      .finally(() => setLoading(false));
    }
  }, []);

  return (
      <Page title="Home">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 3 }}>
            Randomize Items
          </Typography>
          {!loading && (
              <>
                <Box marginBottom={2} width="100%">
                  <Categories {...{categories, setSelectedItems}} />
                </Box>

                <Randomizer items={selectedItems} />
              </>
          )}
        </Container>
      </Page>
  )
}