import React from 'react';
import Page from "../../components/Page";
import {useState} from "react";
import useInputs from "../../hooks/useInputs";
import GenericInputLayout from "../../layouts/GenericInput/GenericInputLayout";
import {useSnackbar} from "notistack";
import {ItemInputs} from "./ItemInputs";
import {CreateItem, DeleteItem, UpdateItem} from "../../api/ItemApi";
import {GetCategoriesAndItems} from "../../api/ItemApi";
import {List} from "@mui/material";
import ItemList from "../../components/Item/ItemList";

export default function ItemPage() {
  const [items, setItems] = useState([]);
  const inputs = useInputs(ItemInputs);
  const {enqueueSnackbar} = useSnackbar();

  const populate = () => {
    GetCategoriesAndItems().then(json => {
      setItems(json);
    })
  }

  React.useEffect(() => {
    populate()
  }, []);

  const handleDelete = async (e, categoryId, item) => {

    return DeleteItem(categoryId, item.id).then(() => {
      populate();
      enqueueSnackbar(`Successfully deleted item: ${item.name}`, {variant: 'success'});
    });
  }

  const handleEdit = async (value) => {
    return UpdateItem(value.category.id, value.id, value).then(json => {
      populate();
    });
  }

  const handleCreateAction = (payload) => {
    return CreateItem(payload.category.id, payload)
  };
  return (
      <Page title="Items" sx={{ position: 'relative'}}>
        <GenericInputLayout
            header="Items By Category"
            newHeader="Add Item"
            inputs={inputs}
            items={items}
            createAction={handleCreateAction}
            onCreate={populate}
            onEdit={handleEdit}
            getDisplayName={item => item.name}
            getListContent={(openModal) => (
                <List>
                  {items.map(item =>
                      <ItemList key={item.id} onDelete={handleDelete} {...{item, inputs, openModal}} />)}
                </List>
            )}
        />
      </Page>
  )
}