import {useContext, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import {useCookies} from "react-cookie";
import React from "react";
import {GlobalContext} from "../providers/GlobalProvider";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

// ----------------------------------------------------------------------

const loginPath = "/login";

export default function RootLayout() {
    const [open, setOpen] = useState(false);
    const ctx = useContext(GlobalContext);
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!ctx.token && !location.pathname.startsWith(loginPath)) {
            navigate(loginPath);
        }
    }, [ctx.token]);

    return (
        <RootStyle>
            <Navbar onOpenSidebar={() => setOpen(true)} />
            <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle>
                <Outlet />
            </MainStyle>
        </RootStyle>
    );
}