import {GetCategories} from "../../api/CategoryApi";

export const ItemInputs = [
  { name: 'name', label: "Name", type: 'text', defaultValue: '', validation: { required: "Please enter a name" } },
  {
    name: 'category',
    label: 'Category',
    getItems: GetCategories,
    optionValue: 'name',
    validation: {
      required: true,
      validate: val => val.name ? true : 'Please choose a Category' },
    type: 'autocomplete',
    defaultValue: { name: '' }
  }
];