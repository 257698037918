import React from 'react';
import {useForm} from "react-hook-form";

import PropTypes from 'prop-types';
import {
  Button,
  Stack,
} from "@mui/material";
import RHFController from "./form-component/RHFController";

InputForm.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.exact({
    type: PropTypes.oneOf(['text', 'number', 'select', 'textarea', 'boolean', 'date', 'checkbox', 'autocomplete', 'choice', 'hidden']),
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    defaultValue: PropTypes.any,
    optionValue: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.string,
      children: PropTypes.array
    })),
    multiple: PropTypes.bool,
    getItems: PropTypes.func,
    validation: PropTypes.object,
  })).isRequired,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,

}

export default function InputForm ({ inputs, onSubmit=()=>{}, submitText="submit" }) {
  const { control, handleSubmit, setFocus, reset } = useForm();

  return (
      <form onSubmit={handleSubmit((data, e) => onSubmit(data, setFocus, reset))}>
        <Stack justifyItems="flex-end" spacing={2}>
          { inputs.map(input => {
            const key = input.id ? `${input.id}-${input.name}` : `${input.type}-${input.name}`;

            return (
                <RHFController
                    key={key}
                    {...{input, control}}
                />
            )}
          )}
          <Button type="submit" variant="contained" sx={{ marginTop: 2, marginLeft: 'auto' }}>{submitText}</Button>
        </Stack>
      </form>
  )
}