import {useCookies} from "react-cookie";
import Cookies from 'js-cookie';
import * as FetchUtil from "../utils/FetchUtil";
import {refreshToken} from "./LoginApi";
import * as LoginActions from "../actions/LoginActions"
import * as LogoutActions from "../actions/LogoutAction"

let refreshing = false;
const {fetch: origFetch} = window;
window.origFetch = origFetch;

export default function useFetchConfig(ctx)  {

  window.fetch = async (resource, config = {}) => {

    const apiUrl = !resource.endsWith(".hot-update.json") ? FetchUtil.getBaseUrl() + resource : resource;

    if (Cookies.get('token')) {
      config.headers = FetchUtil.applyAuthHeader(config.headers, Cookies.get('token'));
    }

    return origFetch(apiUrl, config).then(response => {
      if (response.status === 401) {
        return refresh().then(
            () => {
              return fetch(resource, config);
            });
      } else if (response.status === 204) {
        return Promise.resolve({});
      }

      return response.json();
    }).then(json => {
      if (!json || (json.status && json.status !== 200)) {
        return Promise.reject(
            json ? json.message : "Error occurred, please try again later");
      }

      return json;
    });
  };


  function refresh() {
    if (!refreshing) {
      refreshing = true;
      return refreshToken(origFetch, {refreshToken: Cookies.get('refreshToken')}).then(response => {
        if (response.status === 401) {
          LogoutActions.expireSession();
          throw new Error('Credentials expired');
        } else if (!response.ok) {
          throw new Error("An error occurred refreshing token")
        }

        return response.json();
      }).then(json => {
        LoginActions.handleLogin(ctx, json);

        return json
      }).catch(error => {
        LogoutActions.expireSession();
      }).finally(() => {
        refreshing = false;
      });
    } else {
      return new Promise((resolve, reject) => {
        (function check() {
          if (refreshing) {
            setTimeout(check, 100)
          } else {
            resolve();
          }
        })();
      });
    }
  }
}
