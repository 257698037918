import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Iconify from "../../components/Iconify";
import React from "react";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {useSnackbar} from "notistack";

export default function GenericListItems({ items, inputs, onDelete, setOpen, getDisplayName }) {
  const {enqueueSnackbar} = useSnackbar();

  const handleItemSelect = (e, item) => {
    inputs.updateEditInputs(item);

    setOpen(true);
  }

  const handleDelete = (e, item) => {
    e.stopPropagation();

    onDelete(e, item).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  return (
      <List>
        <>
          {items.map(item => (
              <ListItemButton key={item.id} onClick={ (e) => handleItemSelect(e, item) }>
                <ListItemText>{getDisplayName(item)}</ListItemText>
                <ListItemIcon onClick={(e) => handleDelete(e, item)} sx={{ minWidth: 16 }}>
                  <Iconify icon="ep:close-bold" />
                </ListItemIcon>
              </ListItemButton>
          ))}
        </>
      </List>
  );
}