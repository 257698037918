import {Card, CardContent, CardHeader, Modal} from "@mui/material";
import InputForm from "../../components/form/InputForm";
import React from 'react';
import {ErrorHandler} from "../../utils/ErrorHandler";
import {useSnackbar} from "notistack";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
}

export default function GenericEditModal({ inputs, open, handleModalClose, onEdit }) {
  const {enqueueSnackbar} = useSnackbar();

  const handleEdit = (values) => {
    onEdit(values).then(() => {
      handleModalClose();
    }).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  return (
      <Modal open={open} onClose={handleModalClose}>
        <Card sx={style}>
          <CardHeader title="Edit" />
          <CardContent>
            <InputForm inputs={inputs.editInputs} onSubmit={handleEdit} submitText="Change" />
          </CardContent>
        </Card>
      </Modal>
  )
}