import {Card, CardContent, CardHeader} from "@mui/material";
import InputForm from "../../components/form/InputForm";
import React, {useContext} from "react";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {useSnackbar} from "notistack";
import {GlobalContext} from "../../providers/GlobalProvider";


export default function GenericAddItem({ header, inputs, createAction, onCreate }) {
  const {enqueueSnackbar} = useSnackbar();
  const context = useContext(GlobalContext);

  const handleCreate = (values, setFocus, reset) => {
    const payload = {
      ...values,
      owner: { id: context.user.id }};

    createAction(payload).then(() => {

      setFocus(inputs[0].name);

      const resetInputs = {};
      inputs.forEach(it => resetInputs[it.name] = it.defaultValue);
      reset(resetInputs);

      onCreate();
    }).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  return (
      <Card>
        <CardHeader title={header} />
        <CardContent>
          <InputForm inputs={inputs} onSubmit={handleCreate} submitText="Add" />
        </CardContent>
      </Card>
  );
}