import React from 'react';
import {
  Checkbox,
  FormControl, FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import AutocompleteTextbox from "./AutocompleteTextbox";
import RHFChoice from "./RHFChoice";

export default function RHFComponent({ input, rhf, control }) {
  const { field, fieldState: { error } } = rhf;
  const fieldWithoutRef = {...field};
  delete fieldWithoutRef.ref;

  return input.type !== 'hidden' ? (
      <>
        { input.type === 'text' && (
            <TextField type="text" label={input.label} fullWidth {...fieldWithoutRef} inputRef={field.ref} error={!!error} helperText={error?.message} />
        )}
        { input.type === 'number' && (
            <TextField type="number" label={input.label} fullWidth {...fieldWithoutRef} inputRef={field.ref} error={!!error} helperText={error?.message} />
        )}
        { input.type === 'textarea' && (
            <TextField type="text" label={input.label} multiline minRows={2} maxRows={4} fullWidth {...fieldWithoutRef} inputRef={field.ref} error={!!error} helperText={error?.message} />
        )}
        { input.type === 'date' && (
            <DesktopDatePicker
                label={input.label}
                inputFormat="MM/dd/yyyy"
                {...fieldWithoutRef}
                ref={field.ref}
                renderInput={(params) =>
                    <TextField {...params} error={!!error} helperText={error?.message} />}
            />
        )}
        { input.type === 'select' && (
            <FormControl fullWidth>
              <InputLabel id={input.name}>{input.label}</InputLabel>
              <Select labelId={input.name} label={input.label} {...fieldWithoutRef} inputRef={field.ref} error={!!error}>
                {input.options.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
        )}
        { input.type === 'checkbox' && (
            <FormControlLabel label={input.label} control={<Checkbox {...fieldWithoutRef} inputRef={field.ref} defaultChecked={input.value} />} />
        )}
        { input.type === 'boolean' && (
            <FormControl fullWidth>
              <InputLabel id={input.name}>{input.label}</InputLabel>
              <Select labelId={input.name} label={input.label} {...fieldWithoutRef} inputRef={field.ref} error={!!error}>
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </FormControl>
        )}
        { input.type === 'autocomplete' && (
            <AutocompleteTextbox {...{input, field, error}} />
        )}
        { input.type === 'choice' && (
            <RHFChoice {...{input, field, fieldWithoutRef, control, error}} />
        )}
      </>
  ) : (
      <input type="hidden" {...field} />
  )
}