import FetchWrapper from "./FetchWrapper";

export const GetCategoriesAndItems = () => {
  return fetch(`/categories/items`)
}

export const GetItemsByCategory = (courseId) => {
  return fetch(`/categories/${courseId}/items`)
}

export const CreateItem = (id, item) => {
  return fetch(`/categories/${id}/items`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  });
}

export const UpdateItem = (courseId, id, item) => {
  return fetch(`/categories/${courseId}/items/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  });
}

export const UpdateItems = (courseId, items) => {
  return fetch(`/categories/${courseId}/items`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(items)
  });
}

export const DeleteItem = (categoryId, id) => {
  return fetch(`/categories/${categoryId}/items/${id}`, { method: 'DELETE' })
}