import {Navigate, useRoutes} from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import RootLayout from "./layouts/RootLayout";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ItemPage from "./pages/Items/ItemPage";
import CategoryPage from "./pages/Categories/CategoryPage";


export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <RootLayout />,
      children: [
        {path: '/', element: <Navigate to="/home"/>},
        { path: 'home', element: <HomePage />},
        { path: 'categories', element: <CategoryPage />},
        { path: 'items', element: <ItemPage />},
        { path: '404', element: <NotFoundPage /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: 'login', element: <LoginPage />},
    { path: 'register', element: <RegisterPage />},
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}