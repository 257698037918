import {Link as RouterLink, useNavigate} from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import LoginForm from '../components/form/LoginForm';
import {loginAction} from "../actions/LoginActions";
import {useContext} from "react";
import {GlobalContext} from "../providers/GlobalProvider";
import {useSnackbar} from "notistack";
import {ErrorHandler} from "../utils/ErrorHandler";
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const context = useContext(GlobalContext);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    loginAction(context, values).then(json => {
      enqueueSnackbar("Successfully logged in", {variant: 'success'});
      navigate('/home', { replace: true });
    }).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  return (
      <Page title="Login">
        <RootStyle>
          <HeaderStyle>
            <Logo />

            {smUp && (
                <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                  Don’t have an account? {''}
                  <Link variant="subtitle2" component={RouterLink} to="/register">
                    Get started
                  </Link>
                </Typography>
            )}
          </HeaderStyle>

          {mdUp && (
              <SectionStyle>
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                  Hi, Welcome Back
                </Typography>

              </SectionStyle>
          )}

          <Container maxWidth="sm">
            <ContentStyle>
              <Typography variant="h4" gutterBottom>
                Sign in to Simple Randomizer
              </Typography>

              <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

              <LoginForm onSubmit={handleSubmit} />

              {!smUp && (
                  <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                    Don’t have an account?{' '}
                    <Link variant="subtitle2" component={RouterLink} to="/register">
                      Get started
                    </Link>
                  </Typography>
              )}
            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>
  );
}