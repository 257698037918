import FetchWrapper from "./FetchWrapper";

export const GetCategories = () => {
  return fetch("/categories")
}

export const CreateCategory = (item) => {
  return fetch('/categories', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  });
}

export const UpdateCategory = (id, item) => {
  return fetch(`/categories/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  });
}

export const DeleteCategory = (id) => {
  return fetch(`/categories/${id}`, { method: 'DELETE' })
}