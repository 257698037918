import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export default function Categories({ categories, setSelectedItems }) {
  const [selected, setSelected] = React.useState('');

  React.useEffect(() => {
    if (categories.length > 0) {
      setSelected(categories[0].id);
      setSelectedItems(categories[0].items);
    }
  }, [categories]);

  const handleChange = (e) => {
    setSelected(e.target.value);
    const category = categories.find(it => it.id === e.target.value);
    setSelectedItems(category.items);
  }

  return (
      <FormControl fullWidth>
        <InputLabel id="categories-label">Categories</InputLabel>
        <Select
            labelId="categories-label"
            id="categories"
            value={selected}
            label="Categories"
            onChange={handleChange}
        >
          {categories.map(category => (
            <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
  )
}