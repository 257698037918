import React from 'react';
import {Button, Collapse, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import { Wheel } from 'react-custom-roulette'

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const colors = [
  "#EE4040",
  "#F0CF50",
  "#815CD1",
  "#3DA5E0",
  "#34A24F",
  "#FF9000"
]

export default function Randomizer({ items }) {
  const [spinning, setSpinning] = React.useState(false);
  const [winner, setWinner] = React.useState(0);
  const [winnerName, setWinnerName] = React.useState();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setWinnerName(null);
    setData(items.map(it => ({
      option: it.name
    })));
  }, [items])

  const spin = () => {
    if (!spinning) {
      setWinnerName(null);
      setWinner(getRandomInt(items.length));
      setSpinning(true);
    }
  }

  const handleStop = () => {
    setSpinning(false);
    setWinnerName(data[winner]?.option);
  }

  return items.length > 1 ? (
      <Stack display="flex" alignItems="center" spacing={2}>
        <Wheel
            data={data}
            mustStartSpinning={spinning}
            prizeNumber={winner}
            backgroundColors={colors}
            radiusLineWidth={2}
            outerBorderWidth={2}
            spinDuration={0.65}
            textDistance={60}
            onStopSpinning={handleStop}
        />
        <Collapse in={winnerName}>
          <>
            {winnerName && (
                <Typography>
                  The winner is <b>{winnerName}!</b>
                </Typography>
            )}
          </>

        </Collapse>
        <Button variant="outlined" onClick={spin}>Spin</Button>
      </Stack>
  ) : (
      <Typography>
        Too few items to randomize. Please add at least two <Link to="/items">here</Link>
      </Typography>
  )
}